import { Component, css, html, LitElement } from '@rxdi/lit-html';

import SocialImages from './social.png';
/**
 * @customElement footer-component
 */
@Component({
  selector: 'footer-component',
  style: css`
    .footer {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  `,
  template() {
    return html`
      <div class="footer">
        <img style="transform: scale(0.4);" src=${SocialImages} alt="" />
      </div>
    `;
  }
})
export class FooterComponent extends LitElement { }
