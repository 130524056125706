import { FlexLayout } from '@rhtml/modifiers';
import { Component, css, html, LitElement, property } from '@rxdi/lit-html';
import { Router } from '@rxdi/router';
import { Padding } from '@shared/modifiers/padding';
import { Observable, of } from 'rxjs';

import { Logo } from '../navbar/logo';

interface Route {
  text: string;
  route: string;
}

/**
 * @customElement navbar-component
 */
@Component<NavbarComponent>({
  selector: 'navbar-component',
  styles: [
    css`
      .button {
        cursor: pointer;
      }

      .logo {
        user-select: none;
        cursor: pointer;
      }
    `
  ],
  modifiers: [...FlexLayout, Padding],
  template(this) {
    return html`
      <div
        padding="40px"
        fxLayout="row wrap"
        fxLayoutAlign="start center"
        fxLayoutGap="5px"
      >
        <div
          @click=${() => this.router.go('/')}
          class="logo"
          fxLayoutAlign="center"
        >
          ${Logo}
          <h2>Reactive Solutions</h2>
        </div>
        <div fxLayout="row" fxLayoutAlign="center">
          <r-for .of=${this.items}>
            <r-let
              .item=${(item: Route) => html`
                <div
                  style="flex: 1"
                  class="button"
                  fxLayoutAlign="center"
                  @click=${() => this.router.go(item.route)}
                >
                  ${item.text}
                </div>
              `}
            ></r-let>
          </r-for>
        </div>
        <div fxLayoutAlign="center center">
          <rx-button palette="primary">Enter App</rx-button>
        </div>
      </div>
    `;
  }
})
export class NavbarComponent extends LitElement {
  @property({ type: Number }) counter = 0;

  @Router() router: Router;

  items: Observable<Route[]> = of([
    { text: 'Home', route: '/' },
    { text: 'Service', route: '/flex' },
    { text: 'Team', route: '/flex' },
    { text: 'Blog', route: '/flex' }
  ]);
}
