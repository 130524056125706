import { AngularLayout, FlexLayout } from '@rhtml/modifiers';
import { Component, css, html, LitElement } from '@rxdi/lit-html';
import { InputStyle } from '@rxdi/ui-kit/styles/form/input';

import { BackgroundImage } from './bg';

/**
 * @customElement home-component
 */
@Component<HomeComponent>({
  selector: 'home-component',
  styles: [
    css`
      .launch {
        color: white;
        background: #467792;
        height: 100px;
      }
    `,
    InputStyle
  ],
  modifiers: [...FlexLayout, ...AngularLayout],
  template(this) {
    return html`
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="25%">
          <h1 style="font-size: 45px">
            Grow Your Business with Development company
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam,
          </p>

          <div>
            <input
              style="width: 300px;"
              placeholder="Email address"
              class="rx-input"
              type="text"
            />
            <rx-button palette="danger">Learn more</rx-button>
          </div>
        </div>
        <div style="width: 450px;" fxFlexOffset="50px">${BackgroundImage}</div>
      </div>
    `;
  }
})
export class HomeComponent extends LitElement { }
