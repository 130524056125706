import { svg } from '@rxdi/lit-html';

export const BackgroundImage = svg`
<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2709.17 2038.61">
  <g id="Layer_2" data-name="Layer 2">
    <g id="image">
      <ellipse cx="1378.12" cy="2013.7" rx="935.28" ry="24.76" style="fill:#ebe8f2" />
      <rect x="1416.36" y="1504.41" width="261.46" height="470.33" style="fill:#573d78" />
      <path d="M1321.42,1854.53h458.85a88.38,88.38,0,0,1,88.38,88.38v67.36a0,0,0,0,1,0,0H1233a0,0,0,0,1,0,0v-67.36A88.38,88.38,0,0,1,1321.42,1854.53Z" style="fill:#573d78" />
      <path d="M2362.79,1733.12h-1684a66.61,66.61,0,0,1-66.62-66.62V549.09a66.61,66.61,0,0,1,66.62-66.62h1684a66.62,66.62,0,0,1,66.63,66.62V1666.5A66.63,66.63,0,0,1,2362.79,1733.12Z" style="fill:#483261" />
      <path d="M2318.19,1733.12H642.73a70.92,70.92,0,0,1-70.92-70.91V553.38a70.92,70.92,0,0,1,70.92-70.91H2318.19a70.92,70.92,0,0,1,70.92,70.91V1662.21A70.92,70.92,0,0,1,2318.19,1733.12Z" style="fill:#604385" />
      <rect x="616.8" y="538.36" width="1717.71" height="1113.45" style="fill:#3a284d" />
      <polygon points="1416.36 1733.12 1677.81 1733.12 1677.81 1827.66 1416.36 1733.12" style="fill:#241930" />
      <line x1="1421.6" y1="1854.53" x2="1659.85" y2="1854.53" style="fill:none;stroke:#241930;stroke-linecap:round;stroke-linejoin:round;stroke-width:6.19897px" />
      <polygon points="2132.19 1510.59 851.13 1510.59 855.57 729.16 2136.64 729.16 2132.19 1510.59" style="fill:#6d4b91" />
      <polygon points="2136.45 732.36 855.38 732.36 856.02 636.69 2137.09 636.69 2136.45 732.36" style="fill:#7f58a8" />
      <ellipse cx="955.01" cy="684.52" rx="16.44" ry="16.36" transform="translate(-204.61 874.01) rotate(-44.91)" style="fill:#5c407a" />
      <ellipse cx="1023.74" cy="684.52" rx="16.44" ry="16.36" transform="translate(-184.47 922.91) rotate(-44.93)" style="fill:#5c407a" />
      <ellipse cx="1092.47" cy="684.52" rx="16.44" ry="16.36" transform="translate(-164.4 971.46) rotate(-44.93)" style="fill:#5c407a" />
      <line x1="1159.59" y1="815.15" x2="1318.15" y2="816.07" style="fill:none;stroke:#cfd6e6;stroke-linecap:round;stroke-linejoin:round;stroke-width:10.1941px" />
      <line x1="1318.15" y1="1243.41" x2="1846.9" y2="1245.28" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:10.1941px" />
      <line x1="1173.29" y1="1243.22" x2="1258.1" y2="1243.33" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:10.1941px" />
      <line x1="1582.35" y1="1338.64" x2="1844.44" y2="1338.97" style="fill:none;stroke:#f2b622;stroke-linecap:round;stroke-linejoin:round;stroke-width:10.1941px" />
      <line x1="1559.18" y1="913.77" x2="1856.95" y2="912.06" style="fill:none;stroke:#f2b622;stroke-linecap:round;stroke-linejoin:round;stroke-width:10.1941px" />
      <line x1="1249.66" y1="913.37" x2="1452.48" y2="913.63" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:10.1941px" />
      <line x1="1257.73" y1="1016.06" x2="1859.16" y2="1016.83" style="fill:none;stroke:#f2b622;stroke-linecap:round;stroke-linejoin:round;stroke-width:10.1941px" />
      <line x1="1548.75" y1="1136.63" x2="1851.09" y2="1132.64" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:10.1941px" />
      <line x1="1382.74" y1="1136.62" x2="1490.79" y2="1138.28" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:10.1941px" />
      <line x1="1267.17" y1="1136.27" x2="1336.7" y2="1136.36" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:10.1941px" />
      <circle cx="1471.64" cy="686.94" r="133.73" transform="translate(-54.71 1241.81) rotate(-45)" style="fill:#f2b622" />
      <polyline points="1423.27 677.47 1468.59 730.27 1520.95 658.44" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:10.6569px" />
      <rect x="595.77" y="275.33" width="1068.47" height="144.94" rx="41.13" style="fill:#ab9dd4" />
      <ellipse cx="720.86" cy="338.39" rx="29.74" ry="30.22" transform="translate(118.8 859.61) rotate(-66.03)" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:9.62156px" />
      <line x1="780.3" y1="387.02" x2="743.1" y2="358.75" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:9.62156px" />
      <path d="M2350,1163.86c-30.73-39.15-40.6-55.54-70.55-95.57l-27.71,7.87c48.07,89.31,41.2,119,77.42,142.59C2373.15,1205.58,2355.07,1174.47,2350,1163.86Z" style="fill:#c95836" />
      <path d="M2283.44,1073.07l-10.85-22.64a21,21,0,0,0-10.49-10.13l-18.61-8.16a10.13,10.13,0,0,0-14.19,8.74h0l15.24,27.68a31.56,31.56,0,0,0,16.52,13.83l12.08,4.63Z" style="fill:#c95836" />
      <polygon points="2289.45 1076.06 2257.15 1100.45 2283.44 1150.66 2320.17 1113.12 2289.45 1076.06" style="fill:#ba9cd9;fill-rule:evenodd" />
      <path d="M2406.88,1984.16s30-200.63-8.75-280L2419.29,1432l-126.85,8.75,78.07,545.41Z" style="fill:#e3633d;fill-rule:evenodd" />
      <ellipse cx="2487.45" cy="2013.02" rx="221.72" ry="16.51" style="fill:#ebe8f2" />
      <path d="M2374.85,1049.09s3.56,25.12,6.5,45.93c.79,5.54,5,10.31,11,12.46a20.62,20.62,0,0,0,18.07-2.06l4.87-3.26c7-4.7,10.25-12.46,8.26-19.93l-14.42-54-34.31,20.86Z" style="fill:#c95836;fill-rule:evenodd" />
      <polygon points="2375.43 1062.89 2378.92 1077.84 2393.87 1063.19 2375.43 1062.89" style="fill:#873b24;fill-rule:evenodd" />
      <path d="M2418.41,1022.57a13.4,13.4,0,0,0-8.28-17L2386,997.32c-6.94-2.37-13.44,1.86-16.85,8.43-4,7.75-8.49,25.17-10.22,38.19-1,7.35,2.27,15.54,9.21,17.91,7.41,2.52,32,3,38.67-9.71,5.06-9.64,8.14-19.24,11.62-29.57Z" style="fill:#c95836;fill-rule:evenodd" />
      <path d="M2404.45,1059.41c21-1.51,35.77-21.77,30.16-42a34.65,34.65,0,0,0-4.7-10.86c-12.2-16.63-30.54-27.72-55-13.26,0,0-2,33.23,29.53,66.16Z" style="fill:#241930;fill-rule:evenodd" />
      <path d="M2396,1042.2c-3.77,4.76-10,6.07-13.87,2.92s-4-9.55-.2-14.31,10-6.06,13.87-2.92S2399.74,1037.44,2396,1042.2Z" style="fill:#c95836;fill-rule:evenodd" />
      <path d="M2500.66,1730l117.43,218c22.8,8.26,34.49-12.21,28.07-33.39-26.95-91.91-25.39-140.81-101.22-222.76Z" style="fill:#e3633d;fill-rule:evenodd" />
      <path d="M2387.75,1464.81l69.26,227c2.84,10.65,9.28,25.94,14.35,35.73l111.72,197.82,58.8-33.81c-14.69-94.64-34.8-139.29-88.15-236.47l-33.91-214.33-60,2.41Z" style="fill:#ccc5d6;fill-rule:evenodd" />
      <path d="M2423.32,1454.11l67.54,221.35c2.85,10.66,9.28,26,14.35,35.74l110.38,195.45-32.51,18.69-111.72-197.82c-5.07-9.79-11.51-25.08-14.35-35.73l-69.26-227Z" style="fill:#ada7b5;fill-rule:evenodd" />
      <path d="M2617,1947.38l-.88,31.14c.56,6.69-9,33.44-9,33.44h0c2.8,5.58,8.37,3.22,11.68.14l47.42-64.08s18.68-22.31-19.38-31.09C2643,1932.33,2628,1951.13,2617,1947.38Z" style="fill:#221f14;fill-rule:evenodd" />
      <path d="M2473.93,1297.11l-173.38,13.21c-28.2,10.35-16.93,173-16.93,173l114,5.28c54.37-.86,119.75,7.27,122.2-47.82C2524.31,1339.67,2473.93,1297.11,2473.93,1297.11Z" style="fill:#ccc5d6;fill-rule:evenodd" />
      <path d="M2350.44,1090.55l63.68-7.45,17.46.69,48.57,16.93c22.35,11.06,32.65,33.24,34.66,66.78l7.91,76c1.27,97.85,5.57,113.93,9.47,172.82-94.09,34.93-236.06,29.78-265.76-13.38,2.64-62.19,1.45-161,15-213.69C2293.53,1142,2328.76,1126.48,2350.44,1090.55Z" style="fill:#ba9cd9;fill-rule:evenodd" />
      <path d="M2369.26,1977.4l-17.45,17.22c-4.95,4.53-32,13.33-32,13.33h0c-2.69,5.63,2.57,8.6,7,9.34l79.71-1.67s18.4-3.82,2.07-39.3C2394.16,1982.72,2373,1988.44,2369.26,1977.4Z" style="fill:#221f14;fill-rule:evenodd" />
      <path d="M2283.62,1483.27,2313,1713.75l38.61,231.07h92.83s-8.67-40.18-7.65-52.92,4.4-133.66-23.89-189.29l2.24-226.55Z" style="fill:#ccc5d6;fill-rule:evenodd" />
      <line x1="2456.72" y1="1488.63" x2="2416.21" y2="1489.96" style="fill:none;stroke:#ada7b5;stroke-linecap:round;stroke-linejoin:round;stroke-width:7.483270000000001px" />
      <path d="M2329.21,1105.78S2183,1236.18,2195,1254.45l5.07,8.22c6.59,10.67,22.52,12.11,32.36,2.93l73.34-68.46,14.57-13Z" style="fill:#c95836;fill-rule:evenodd" />
      <path d="M2218.13,1260.49H1970c-14.85,0-27-12.47-27-27.71l.35-233.2c0-15.24,12.19-27.71,27-27.71h248.1c14.86,0,27,12.47,27,27.71l-.35,233.2C2245.16,1248,2233,1260.49,2218.13,1260.49Z" style="fill:#f4a1f9" />
      <path d="M1992.18,1111.68l2.19-12.68,73.61-23.75-2.53,14.65-31.74,9.23-22.22,6.53-.1.6,19.71,8,28.2,11.3-2.53,14.64Z" style="fill:#fff" />
      <path d="M2115.55,1052.13l12.11.41-58.86,130.14-12.11-.42Z" style="fill:#fff" />
      <path d="M2123.27,1127.74l31.75-9.24,22.21-6.53.11-.6-19.72-8-28.19-11.3,2.52-14.65,64.6,28.51-2.19,12.68-73.62,23.75Z" style="fill:#fff" />
      <path d="M2193.66,1217.22c-47.8,13.85-66.7,16.87-115,29.67l-3.07,28.64c100.81-11.16,125.82,6.34,161.2-18.45C2241,1211.37,2205.39,1216.5,2193.66,1217.22Z" style="fill:#c95836" />
      <path d="M2084.56,1245l-25.06,1.59a21,21,0,0,0-13.32,5.93l-14.53,14.21a10.13,10.13,0,0,0,2.79,16.43h0l31.38-3.78a31.56,31.56,0,0,0,19-10.14l8.82-9.48Z" style="fill:#c95836" />
      <path d="M2350.44,1090.55s-41.85,13.67-58,32.48-78.24,76.15-78.24,76.15l-109.63,29.23-13.85,51s98.77,5.18,117.15,3.33,126.7-86.1,126.7-86.1Z" style="fill:#ba9cd9;fill-rule:evenodd" />
      <polyline points="2277.59 1239.38 2327.41 1196.64 2337.74 1188.72" style="fill:none;stroke:#9677b5;stroke-linecap:round;stroke-linejoin:round;stroke-width:5.01789px;fill-rule:evenodd" />
      <path d="M1981.57,52c.4,10-5.77,18.3-13.78,18.62s-14.83-7.49-15.23-17.46,5.77-18.3,13.78-18.62S1981.17,42,1981.57,52Z" style="fill:#99938b" />
      <path d="M2034.18,38.1s4.56,22.7-11.73,28.59-32-41.39-32-41.39Z" style="fill:#572a22;fill-rule:evenodd" />
      <path d="M1921.79,409c6.5,4.19,4.93,13.26-3.72,22.07-15.3,15.6-71.58,40.68-67.51,93.27,3,38.17-39,166.51-67.27,223.78-4.12,8.33,5.13-5.38-10,8.15l-3.58,15.24c-10.38-5.12-15.51-5.08-15.76-13.79l16.58-261.11c-.17-7.87,20.28-23.62,27.36-33,13.85-18.34-4.39-27.92,19.8-34.84l52.12-14.91s-.79,20.56,6.14,25Z" style="fill:#874b2b;fill-rule:evenodd" />
      <path d="M1754.86,747l-21.46,15.11c-4.44,3.77-28.14,10.55-28.14,10.55h0c-2.51,4.79,2,7.53,5.8,8.31l69.18,1.09s16.08-2.73,3-34C1770.51,753.16,1757.78,756.71,1754.86,747Z" style="fill:#fff;fill-rule:evenodd" />
      <path d="M1976.39,407.58c6.72,4.2,6.9,13.32.54,22.2-11.24,15.72-58.41,41.07-45.24,93.94,9.56,38.36-4.48,167-20.16,224.64-2.28,8.4,2.31-4.94-9.16,8.71l-.56,15.32c-10.45-5.13-13.63-5.87-15.41-14.63l-33.15-261.71c-1.57-7.92,14.38-23.81,19.19-33.26,9.42-18.47-9-28.06,11.92-35.08l45.18-15.11s3,20.67,10.12,25.14Z" style="fill:#874b2b;fill-rule:evenodd" />
      <path d="M1886.4,757.76l-12.59,23c-2.32,5.34-20.61,21.88-20.61,21.88h0c-.14,5.41,5.08,5.89,8.88,4.89L1924.65,778s13.23-9.54-12.27-31.85C1903.15,756.37,1893.3,765.16,1886.4,757.76Z" style="fill:#fff;fill-rule:evenodd" />
      <path d="M1953.75,359.63s-124.94,46.23-157,67.27-33.7,35.5-35.4,75.21c-1.15,26.83-14.73,216.17-14.73,216.17s37.81,15,62.49,10.28l42.3-185.51s15.75,194.2,17.11,194.34,44.47,8.09,54-4.71c0,0,19.44-100.46,19.21-165.17-.29-82.36,14.87-83.29,24.92-82.2s126.68-2.91,126.68-2.91,38.71-7.82,38-67.55c-.53-47.25-5-57.72-5-57.72Z" style="fill:#ba9cd9;fill-rule:evenodd" />
      <path d="M1868.8,266.77l37.31-112.08c10.65-29.12,45.11-56.77,66.57-40.59h0c8.46,6.38,10.84,20.07,5.34,30.75l-68.47,133Z" style="fill:#874b2b;fill-rule:evenodd" />
      <path d="M1944.77,107.49l40.68-14.06,34.89-4,17.1.71,65,22.82A47.79,47.79,0,0,1,2126,172.33l-16.08,74.37c16.77,56.17,26,70.18,22.93,127.93-85,14.56-234.44,4.53-241.17-3.2C1938.32,291.9,1915.3,165.7,1944.77,107.49Z" style="fill:#d3ccde;fill-rule:evenodd" />
      <path d="M1986.29,93.5s-20.56,50.55,14.17,52.86,53.86-50.25,53.86-50.25L2029,84.78Z" style="fill:#874b2b" />
      <path d="M2108.81,325.39,2085.3,191.92c-6.47-27.93,11.4-48.91,37.57-44.1h0c10.31,1.9,19.44,12.15,20.51,23l21,127.11Z" style="fill:#874b2b;fill-rule:evenodd" />
      <path d="M2065.76,157.92l22.85,96.79,75.93-28.15c-10.93-58.81-23.55-93.51-62.14-113.6C2060,90.87,2065.76,157.92,2065.76,157.92Z" style="fill:#d3ccde;fill-rule:evenodd" />
      <path d="M1944.77,107.49s-38.08,10.68-52.66,58.05-16.75,48.14-16.75,48.14l51.89,21.15,40.15-98.15Z" style="fill:#d3ccde;fill-rule:evenodd" />
      <path d="M1922.42,242.51s4.82-38.44,13.37-62.07" style="fill:none;stroke:#9f96a8;stroke-linecap:round;stroke-linejoin:round;stroke-width:3.2713300000000003px;fill-rule:evenodd" />
      <polyline points="2092.4 254.72 2092.89 204.18 2096.36 195.47" style="fill:none;stroke:#9f96a8;stroke-linecap:round;stroke-linejoin:round;stroke-width:3.2713300000000003px;fill-rule:evenodd" />
      <path d="M1850.36,545.27c-1.51-1.61-4.21-49.56-5.62-62.91s10.26-47,90.5-62.82" style="fill:none;stroke:#663b24;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.66364px;fill-rule:evenodd" />
      <path d="M1989.85,60.07s3.54,23.09,6.47,42.23c.78,5.09,4.76,9.42,10.42,11.31a19.54,19.54,0,0,0,16.88-2.18l4.53-3.08c6.54-4.44,9.48-11.64,7.56-18.5-5-17.55-13.94-49.54-13.94-49.54l-31.92,19.76Z" style="fill:#874b2b;fill-rule:evenodd" />
      <polygon points="1991.54 77.83 1994.41 91.56 2010.96 71.87 1991.54 77.83" style="fill:#57301c;fill-rule:evenodd" />
      <path d="M1974,55.19S1953,43.51,1966.59,19s30.78,16.89,30.33,17.65S1974,55.19,1974,55.19Z" style="fill:#572a22;fill-rule:evenodd" />
      <path d="M2018,25.65a13.23,13.23,0,0,0-14.82-11.35l-25,3.38c-7.17,1-11,7.57-11.15,14.88-.13,8.63,3.61,26,7.84,38.25,2.38,6.92,8.87,12.72,16,11.74,7.66-1,29.58-11.51,29.86-25.64.22-10.75-1.3-20.59-2.79-31.26Z" style="fill:#874b2b;fill-rule:evenodd" />
      <path d="M2039,41.22s-4.47-27.91-18-34.83c-18.12-9.29-39.21-11-54.46,12.6,0,0-9.26,10.37,28.84,4,4.83-.8,5,23.51,11.44,23.65,8.74.18,19.33-1.34,32.13-5.45Z" style="fill:#572a22;fill-rule:evenodd" />
      <path d="M2030,53.35c-1.23,5.87-6.14,9.76-11,8.7s-7.73-6.68-6.5-12.54,6.14-9.77,11-8.71S2031.21,47.48,2030,53.35Z" style="fill:#c95836;fill-rule:evenodd" />
      <path d="M2163.32,292.43c7.44,22.24-.21,46.64-27,61.79l-139.85,48.44,5.1-29.06,100.16-51.74Z" style="fill:#874b2b;fill-rule:evenodd" />
      <path d="M1975.35,459.12,1857.62,442c-24.81-3.61-30.8-36.69-8.84-48.78l19.62-10.79,155.83,23.2a6.92,6.92,0,0,1,3.27,12.27Z" style="fill:#7d6c96" />
      <path d="M1876.69,254.22c-15.89,16.2-19.48,48.19-7.26,74.41L1889,395.51l29.16-19.26-7-103.51Z" style="fill:#874b2b;fill-rule:evenodd" />
      <path d="M2001.56,373.82l-21.71,13.55a21.34,21.34,0,0,0-9.09,11.77l-6.17,19.78a10.33,10.33,0,0,0,10.46,13.39h0l26.33-18.57a32.21,32.21,0,0,0,12.15-18.3l3.32-12.77Z" style="fill:#874b2b" />
      <path d="M1973.77,455.44l-139.84-17.53L1789,345.72c-1-2.09.35-4.13,2.58-3.85l133.11,16.69a3.85,3.85,0,0,1,2.89,2.11Z" style="fill:#7d6c96;fill-rule:evenodd" />
      <path d="M1970.75,458.44l-139.83-17.53L1786,348.72c-1-2.09.35-4.13,2.58-3.85l133.1,16.69a3.87,3.87,0,0,1,2.9,2.11Z" style="fill:#d3ccde;fill-rule:evenodd" />
      <path d="M1882.41,402.57c2,5.9-2.32,9.95-9.58,9s-14.75-6.43-16.72-12.34,2.33-9.95,9.59-9S1880.44,396.66,1882.41,402.57Z" style="fill:#fff;fill-rule:evenodd" />
      <ellipse cx="2023.38" cy="49.95" rx="14.51" ry="18.06" transform="translate(-0.37 81.34) rotate(-2.3)" style="fill:#99938b" />
      <path d="M2034.72,49.62c.2,5-2.42,9.14-5.85,9.27s-6.37-3.79-6.57-8.77,2.42-9.13,5.85-9.27S2034.52,44.64,2034.72,49.62Z" style="fill:#6e6964" />
      <path d="M2024.24,38s6.13-39.58-33.35-38c-35.4,1.44-33,40.93-33,40.93l4.59-.36s.93-33.06,27.44-34.07c31.1-1.18,27.49,31.1,27.49,31.1Z" style="fill:#99938b" />
      <path d="M1939.27,193.16a1061,1061,0,0,0,121.33,11.05l0,22.33c-42.55.85-79.78-2.94-123.46-9Z" style="fill:#fff;fill-rule:evenodd" />
      <path d="M837.09,1000.22a214.56,214.56,0,0,0-16.55,32.51" style="fill:none;stroke:#adb2b8;stroke-linecap:round;stroke-linejoin:round;stroke-width:5.693890000000001px" />
      <path d="M583.39,2020h0a9.59,9.59,0,0,1-9.69-11.33L764.49,698.28a13.62,13.62,0,0,1,13-11.34h0a9.59,9.59,0,0,1,9.69,11.34L596.37,2008.71A13.61,13.61,0,0,1,583.39,2020Z" style="fill:#8d62bd" />
      <path d="M800.17,2020h0a9.6,9.6,0,0,1-9.69-11.33L981.28,698.28a13.62,13.62,0,0,1,13-11.34h0a9.59,9.59,0,0,1,9.68,11.34L813.16,2008.71A13.62,13.62,0,0,1,800.17,2020Z" style="fill:#8d62bd" />
      <rect x="745.39" y="884.2" width="222.63" height="22.9" rx="6.5" style="fill:#8d62bd" />
      <rect x="720.66" y="1018.88" width="222.63" height="22.9" rx="6.5" style="fill:#8d62bd" />
      <rect x="700.85" y="1153.56" width="222.63" height="22.9" rx="6.5" style="fill:#8d62bd" />
      <rect x="686.76" y="1288.23" width="222.63" height="22.9" rx="6.5" style="fill:#8d62bd" />
      <rect x="671.18" y="1422.91" width="222.63" height="22.9" rx="6.5" style="fill:#8d62bd" />
      <rect x="646.45" y="1557.59" width="222.63" height="22.9" rx="6.5" style="fill:#8d62bd" />
      <rect x="630.71" y="1692.26" width="222.63" height="22.9" rx="6.5" style="fill:#8d62bd" />
      <rect x="604.75" y="1829.74" width="222.63" height="22.9" rx="6.5" style="fill:#8d62bd" />
      <path d="M1244.54,1114.83h0a12,12,0,0,1-16.61-3.43l-40.55-61.64a12,12,0,0,1,3.42-16.61h0a12,12,0,0,1,16.61,3.43l40.56,61.64A12,12,0,0,1,1244.54,1114.83Z" style="fill:#fff;fill-rule:evenodd" />
      <path d="M939.37,1804.31s-64-177.63-43.07-257.14l-68.92-244.52,117.36-15.15,21.34,513Z" style="fill:#e67e5e;fill-rule:evenodd" />
      <path d="M965,1789.53l19.71,21.24c3.7,5,26.79,17.89,26.79,17.89h0c1.45,5.63-3.89,7.39-8.1,7.27l-72.47-15.78s-19.19-5.13,2.07-34.6C945.09,1794,959.57,1798.92,965,1789.53Z" style="fill:#221f14;fill-rule:evenodd" />
      <path d="M748.8,1801.51s-26.75-186.89,9.79-260.53l-5.08-238.41,105-6.11-82.78,506.69Z" style="fill:#e67e5e;fill-rule:evenodd" />
      <path d="M971.33,925.41l-15.49,40.14a14.36,14.36,0,0,1-11.26,9.05,14.09,14.09,0,0,1-13.29-5.44l-3-3.9a18.52,18.52,0,0,1-1.73-19.61L950,899.81l21.35,25.6Z" style="fill:#d97759;fill-rule:evenodd" />
      <polygon points="966.72 937.19 960.64 949.98 952.61 933.8 966.72 937.19" style="fill:#873b24;fill-rule:evenodd" />
      <path d="M940.56,890A12.47,12.47,0,0,1,953,877.54h23.75c6.82,0,11.28,5.67,12.31,12.48,1.22,8-.08,24.72-2.47,36.71-1.35,6.77-6.67,13-13.49,13-7.29,0-29.09-7-31.15-20.16-1.56-10-1.39-19.4-1.35-29.55Z" style="fill:#d97759;fill-rule:evenodd" />
      <path d="M910.83,913.9s11.58-33.44,23.29-41.61c15.74-11,35.22-15.24,52.46,4.85,0,0-8.21,29.87-45.89,49.41Z" style="fill:#241930;fill-rule:evenodd" />
      <path d="M954.43,914.06c1.89,5.32,7,8.34,11.34,6.74s6.37-7.22,4.48-12.55-7-8.34-11.34-6.73S952.53,908.74,954.43,914.06Z" style="fill:#d97759;fill-rule:evenodd" />
      <path d="M1080.32,1090.73c45.27-9.74,62.1-15.6,107.34-26.43l23.15,22.2c-87.53,35.32-102.32,60.24-142.25,55.53C1044.92,1106.44,1070.38,1095.31,1080.32,1090.73Z" style="fill:#d97759" />
      <path d="M1186.53,1064.43l23.28-2a19.52,19.52,0,0,1,13.08,3.62l15.34,11.07a9.44,9.44,0,0,1-.31,15.51h0l-29.4.85a29.37,29.37,0,0,1-18.89-6.71l-9.42-7.5Z" style="fill:#d97759" />
      <path d="M983.35,976.56s121.75,132.26,109,149.65L1087,1134a19,19,0,0,1-29.92,1.72l-60.76-69.32-12.13-13.2Z" style="fill:#d97759;fill-rule:evenodd" />
      <path d="M783.77,1161.73,945.05,1175c26.18,9.81,14.74,161,14.74,161l-106.13,4.25c-50.59-1.12-100,.1-102-51.18C748.08,1195.08,783.77,1161.73,783.77,1161.73Z" style="fill:#f4a1f9;fill-rule:evenodd" />
      <path d="M983.35,976.56l-67.5-32.42-3.57,6.14-30.08,2c-22.79,4.35-33.58,16.36-50.63,48.41,0,0-64.7,157.57-76.7,211.18,75.67,54.77,183.37,83,220.75,51.72C1000.52,1156.47,1005.53,1056.07,983.35,976.56Z" style="fill:#e9e1f5;fill-rule:evenodd" />
      <path d="M776.65,1794.35,800,1811.57c4.58,4.25,29.67,12.6,29.67,12.6h0c2.47,5.25-2.45,8-6.61,8.64l-74.16-2s-17.1-3.67-1.7-36.59C760.64,1800.25,773.07,1804.6,776.65,1794.35Z" style="fill:#221f14;fill-rule:evenodd" />
      <path d="M866.47,1336.09,837.8,1550.42l-37.3,214.81-86.39-.55s8.31-37.34,7.43-49.21-3.31-124.41,23.35-176l6.52-245.34Z" style="fill:#f4a1f9;fill-rule:evenodd" />
      <polygon points="866.47 1336.09 837.8 1550.42 800.5 1765.23 757.76 1764.96 801.59 1554.33 812.17 1341.54 866.47 1336.09" style="fill:#d28ad6;fill-rule:evenodd" />
      <line x1="798.66" y1="1340.06" x2="836.35" y2="1341.54" style="fill:none;stroke:#ad5db0;stroke-linecap:round;stroke-linejoin:round;stroke-width:6.21053px" />
      <path d="M983.35,976.56c22.19,6.74,54.8,47.72,84.37,78.69l-55.36,57-62.87-61Z" style="fill:#e9e1f5" />
      <path d="M813.2,924.11c-11-45-13.1-70.13-23.1-115.57L760,797.4c6.42,94.17-9.56,118.35,12.1,152.23C814.46,955.37,813.41,935.05,813.2,924.11Z" style="fill:#d97759" />
      <path d="M790.48,809.62l-8.36-21.82a19.48,19.48,0,0,0-9-10.19l-16.65-9a9.44,9.44,0,0,0-13.83,7h0l12.06,26.83A29.42,29.42,0,0,0,769,816.58l10.86,5.2Z" style="fill:#d97759" />
      <path d="M952.14,986.72s-159.41-83.1-172.88-66.27l-6.15,7.27A19,19,0,0,0,779.24,957L862,997.66l15.91,8.27Z" style="fill:#d97759;fill-rule:evenodd" />
      <path d="M967,985.75,836.35,921.93c-22.24,12.13-38.39,29.88-37.42,63.4l89.86,53.55Z" style="fill:#e9e1f5" />
      <path d="M835.57,939.45a15,15,0,0,0,.53-12.24c-11.65-32.6,34.85-39.5,49.37-44.74C900.63,877,892.4,851,921.62,850s33.75,15.45,35.14,15.4c2-.06-10.85,24.79-10.85,24.79s-14.53.36-22.13,27-4.63,42.55-27.2,58.61-79.75-30.12-79.75-30.12C827.69,948.74,833,944.18,835.57,939.45Z" style="fill:#241930;fill-rule:evenodd" />
      <path d="M955.17,863.4s5.17,16.65-15,22.37" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:3.7992000000000004px" />
      <polyline points="999.69 1099.97 962.28 1056.22 954.57 1056.22" style="fill:none;stroke:#adb2b8;stroke-linecap:round;stroke-linejoin:round;stroke-width:5.228560000000001px" />
      <path d="M960.55,1324.72l15.24,215.69,6.89,217.92L898,1775.25s.58-38.25-2.67-49.69-28.38-121.18-12.7-177.11l-51-235.36Z" style="fill:#f4a1f9;fill-rule:evenodd" />
      <polygon points="834.66 1862.89 812.18 1859.54 820.1 1806.3 842.25 1809 834.66 1862.89" style="fill:#8d62bd" />
      <ellipse cx="239.49" cy="2013.86" rx="239.49" ry="24.75" style="fill:#ebe8f2" />
      <path d="M261.09,1363s6.35-145.76,29.79-213.53,67-209.16,142.89-185.84S422.63,1095.09,404,1162c-13.41,48.14-49.78,258.61-132.57,258.61C242.87,1420.59,261.09,1363,261.09,1363Z" style="fill:#e6ad20;fill-rule:evenodd" />
      <path d="M178.27,1362c1.6,5.28,6.32,107.38,59.66,97.31,45.39-8.57,36.64-112.21,9.34-186-15.28-41.32-129.88-59.63-122.93,2.26C125.55,1286.38,144.26,1249.93,178.27,1362Z" style="fill:#e6ad20;fill-rule:evenodd" />
      <path d="M242.66,1683.22c-.67-9.59,6.52-272.75-27.69-374.4" style="fill:none;stroke:#362510;stroke-linecap:round;stroke-linejoin:round;stroke-width:5.878830000000001px;fill-rule:evenodd" />
      <path d="M261.1,1613.71s-2.78-366.79,99.26-524.22" style="fill:none;stroke:#362510;stroke-linecap:round;stroke-linejoin:round;stroke-width:5.878830000000001px;fill-rule:evenodd" />
      <path d="M247.27,1273.28s-74.38-38.33-118,37.58a51.33,51.33,0,0,1-44.45,25.92h0c-43,0-66.72-49.77-39.77-83.22,22.11-27.45,60.22-49.44,125.87-42.48C170.93,1211.08,234.41,1218.36,247.27,1273.28Z" style="fill:#f2b622;fill-rule:evenodd" />
      <path d="M264.77,2009.21h-49a79.61,79.61,0,0,1-79.48-75l-21.88-377.37h257L344.18,1935.3A79.61,79.61,0,0,1,264.77,2009.21Z" style="fill:#ccc5d6" />
      <path d="M302.84,1556.84,275.67,1935.3A79.58,79.58,0,0,1,206,2008.55a80.35,80.35,0,0,0,9.75.66h49a79.61,79.61,0,0,0,79.41-73.91l27.16-378.46Z" style="fill:#ada7b5" />
    </g>
  </g>
</svg>

`;
