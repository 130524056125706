import {
  Attribute,
  Input,
  /*   CustomAttributeRegistry, */
  Modifier
} from '@rhtml/custom-attributes';
import { HostBinding } from '@rhtml/decorators';

@Modifier({
  selector: 'padding'
})
export class Padding extends Attribute {
  @Input({ observe: true })
  @HostBinding('style.padding')
  padding: string;
}
