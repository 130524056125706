import { svg } from '@rxdi/lit-html';

export const Logo = svg`
<svg style="transform: scale(0.5); width: 120px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 1020.64">
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <polygon points="179.46 622.96 683.77 688.07 683.16 686.57 449.6 511.27 179.46 622.96" style="fill:#4d4d4d" />
      <polygon points="179.46 622.96 179.46 622.96 449.6 511.27 490.88 210.66 179.46 622.96" />
      <polygon points="683.37 687.17 491.38 210.06 490.88 210.66 449.6 511.27 683.37 687.17" style="fill:#313130" />
      <polygon points="452.3 918.36 39.48 777.02 444.49 1020.64 873.55 779.83 529.36 171.09 760.82 745.17 452.3 918.36" style="fill:#2d2c62;fill-rule:evenodd" />
      <path d="M582.47,277.27q56.06,122.61,112.72,244.52,28.56,61,56.92,121.81,27.5,58.59,55.1,117.2-90,50.78-179.75,101.57Q581.61,888.51,536,914.66q-44.79,25.7-89.48,51.39-81-37.57-161.82-74.93-82.06-37.57-163.83-74.93,80.72,39.66,161.72,79.34,82.07,40,164.33,79.83,92.75-52,185.58-104.08,93.78-53,187.77-106.37Q790.18,702.39,760,639.79,730.71,579.39,701.3,519,642.38,398,582.47,277.27Z" style="fill:#fffce6;fill-rule:evenodd" />
      <polygon points="808.12 290.8 898 717.62 900 245.12 473.45 0 126.55 607.23 501.4 114.49 808.12 290.8" style="fill:#2d2c62;fill-rule:evenodd" />
      <path d="M190.58,507.26q76.65-111,152.41-222.07,37.72-55.6,75.55-111.09Q454.77,120.31,491,66.71,580.71,118,670.34,169q45.84,26,91.69,51.88,45.08,25.24,90,50.49,9.18,88.8,18.54,177.3,9.76,89.71,19.44,179.11-7.22-89.7-14.53-179.51-7.82-90.9-15.63-182-92.13-52.89-184.47-106.08Q581.7,106.83,487.77,53.39q-38.16,57.85-76.55,115.8-37,56-73.84,112.29-73.65,112.39-146.8,225.78Z" style="fill:#fffce6;fill-rule:evenodd" />
      <path d="M452.11,518.88c-6.22-6.41-2.11-11.82,5.51-7.21,76,57.3,170.64,128.62,225.65,175.7-60.43-39.87-155-111.19-231.16-168.49Z" style="fill:#fffce6;fill-rule:evenodd" />
      <path d="M442.38,510.37c8.82-2,11.33,4.21,3.51,8.41-88.17,36.07-197.79,81-266.43,104.18,65.23-31.55,174.75-76.43,262.92-112.59Z" style="fill:#fffce6;fill-rule:evenodd" />
      <path d="M445.89,504.16c.4,9,7.12,9.72,9,1.1,11.42-94.56,31.86-223.18,36-295.5-13.12,71.22-33.57,199.84-45,294.4Z" style="fill:#fffce6;fill-rule:evenodd" />
      <polygon points="820.24 585.6 763.83 322.05 627.55 244.31 820.24 585.6" style="fill:#2d2c62" />
      <polygon points="586.27 790.45 227.16 791.55 448.8 867.58 586.27 790.45" style="fill:#2d2c62" />
      <polygon points="323.55 161.48 135.47 329.46 135.47 493.14 323.55 161.48" style="fill:#2d2c62" />
      <polygon points="85.37 307.32 410.72 16.63 0 250.43 0 742.27 699.4 740.26 85.37 660.93 85.37 307.32" style="fill:#2d2c62;fill-rule:evenodd" />
      <path d="M580.86,734.65q-134.37-11.26-268.54-21.43-67-5.12-134-10.12L49.1,693.78q-.3-103.21-.7-206.35c-.2-35.16-.5-70.32-.7-105.38q-.62-51.54-1-103.07Q119,226.69,191.18,174.5q72.9-53.19,145.59-106.08-74.1,50.93-148.39,102.07-75,52-150,104.18-.15,106.23-.2,212.76.45,107.73.9,215.77l138.67,8.71q67,4.07,134.17,8,134.22,7.68,268.94,14.73Z" style="fill:#fffce6;fill-rule:evenodd" />
      <polygon points="509.32 810.68 345.99 811.08 446.79 845.64 509.32 810.68" style="fill:#fff" />
      <polygon points="246.29 257.24 155.51 338.27 155.51 417.31 246.29 257.24" style="fill:#fff" />
      <polygon points="773.25 461.89 746.09 334.87 680.46 297.51 773.25 461.89" style="fill:#fff" />
      <polygon points="179.46 622.96 683.77 688.07 683.16 686.57 449.6 511.27 179.46 622.96" style="fill:#2d2c62" />
      <polygon points="179.46 622.96 179.46 622.96 449.6 511.27 490.88 210.66 179.46 622.96" style="fill:#2d2c62" />
      <polygon points="683.37 687.17 491.38 210.06 490.88 210.66 449.6 511.27 683.37 687.17" style="fill:#2d2c62" />
      <polygon points="294.79 607.64 576.15 644 445.29 545.43 319.04 597.62 294.79 607.64" style="fill:#fff" />
      <polygon points="422.25 490.14 445.49 320.45 269.74 553.24 422.25 490.14" style="fill:#fff" />
      <polygon points="615.23 598.22 505.41 325.25 481.66 497.95 481.66 497.95 615.23 598.22" style="fill:#fff" />
      <path d="M452.11,518.88c-6.22-6.41-2.11-11.82,5.51-7.21,76,57.3,170.64,128.62,225.65,175.7-60.43-39.87-155-111.19-231.16-168.49Z" style="fill:#fffce6;fill-rule:evenodd" />
      <path d="M442.38,510.37c8.82-2,11.33,4.21,3.51,8.41-88.17,36.07-197.79,81-266.43,104.18,65.23-31.55,174.75-76.43,262.92-112.59Z" style="fill:#fffce6;fill-rule:evenodd" />
      <path d="M445.89,504.16c.4,9,7.12,9.72,9,1.1,11.42-94.56,31.86-223.18,36-295.5-13.12,71.22-33.57,199.84-45,294.4Z" style="fill:#fffce6;fill-rule:evenodd" />
    </g>
  </g>
</svg>
`;
